import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { ButtonSize, ButtonStyle, ButtonType } from '../../common/models/Enums';

interface OwnProps {
  type: 'button' | 'submit' | undefined;
  label: string;
  fullWidth?: boolean;
  buttonStyle: ButtonStyle;
  buttonSize?: ButtonSize;
  buttonType?: ButtonType | ButtonType.pill;
  onClick?(event: any): void;
  onMouseDown?(): void;
  className?: string;
  disabled?: boolean;
  icon?: string;
  dataTestId: string;
  tooltip?: string;
}

type Props = OwnProps;
const Button: React.FC<Props> = (props: Props) => {
  const { label, onClick, onMouseDown, buttonStyle, buttonSize = ButtonSize.large, fullWidth, type, className, disabled, icon, dataTestId, buttonType = ButtonType.pill, tooltip } = props;

  let classes = 'focus:outline-none ';

  switch (buttonStyle) {
    case ButtonStyle.primary:
      classes += buttonType === ButtonType.pill ? 'primary-button' : 'primary-text-button';
      break;
    case ButtonStyle.destructive:
      classes += buttonType === ButtonType.pill ? 'destructive-button' : 'destructive-text-button';
      break;
    case ButtonStyle.warning:
      classes += buttonType === ButtonType.pill ? 'warning-button' : 'warning-text-button';
      break;
    case ButtonStyle.cancel:
      classes += buttonType === ButtonType.pill ? 'cancel-button' : 'cancel-text-button';
      break;
  }

  if (buttonType === ButtonType.pill) {
    switch (buttonSize) {
      case ButtonSize.large:
        classes += ' px-8 py-4 rounded-xl ';
        break;
      case ButtonSize.extraLarge:
        classes += ' px-12 py-6 rounded-xl text-xl ';
        break;
      case ButtonSize.medium:
        classes += ' px-6 py-2 text-sm rounded-xl ';
        break;
      case ButtonSize.small:
        classes += ' px-3 py-1 text-sm rounded-xl ';
        break;
      case ButtonSize.extraSmall:
        classes += ' px-2 py-1 text-xs rounded-xl ';
        break;
      case ButtonSize.dialog:
        classes += ' px-8 py-4 text-center rounded-b-xl ';
        break;
    }
  }

  if (fullWidth) {
    classes += ` w-full`;
  }

  if (className) {
    classes += ` ${className}`;
  }

  if (disabled) {
    classes += ' disabled:opacity-50';
  }

  return (
    <button className={classes} onClick={onClick} type={type} onMouseDown={onMouseDown} disabled={disabled} data-testid={dataTestId}>
      {icon && (
        <span>
          <i className={'mr-2 fa fa-1x fa-' + icon} />
        </span>
      )}
      <span>{label}</span>
      {tooltip && (
        <span className="has-tooltip">
          <i className="fa fa-question-circle text-white ml-2"></i>
          <div className="tooltip rounded-xl shadow-lg p-4 bg-gray-200 text-value max-w-lg text-black">{tooltip}</div>
        </span>
      )}
    </button>
  );
};

const container = compose<Props, OwnProps>()(Button);

export default container;
