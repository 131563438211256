import { combineReducers } from '@reduxjs/toolkit';
import { coreReducer } from 'domains/core/reducer';
import { commonReducer } from 'domains/common/reducer';

export default combineReducers({
  domains: combineReducers({
    core: coreReducer,
    common: commonReducer
  })
});
