import React, { ReactNode } from 'react';

interface OwnProps {
  message?: string;
  children?: ReactNode;
}

type Props = OwnProps;
const FullPageOverlay: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <div className="w-full h-full fixed top-0 left-0 bg-gray-900 opacity-75 z-50"></div>
      <div className="flex items-center justify-center w-full h-full fixed top-0 left-0 flex-col z-50">
        <span className="text-accent my-0 mx-auto flex items-center opacity-100 justify-center ">
          <i className="fa fa-circle-notch fa-spin fa-5x "></i>
        </span>
        <span className="text-white font-bold">{props.message}</span>
      </div>
    </div>
  );
};

export default FullPageOverlay;
