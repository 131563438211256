import _ from 'lodash';
import { all, fork, take } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';

export function* watchForSomething() {
  while (true) {
    try {
      const { payload } = yield take(actionTypes.DO_THING);
    } catch (e) {}
  }
}

export default function* watchForCoreSagas(): any {
  yield all([fork(watchForSomething)]);
}
