import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import rootSagas from './rootSagas';
import rootReducers from './rootReducers';

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    // This only gets called if a saga function does not catch the error itself
    console.log(err);
  }
});

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([thunkMiddleware, sagaMiddleware])
});

if ((module as any).hot) {
  (module as any).hot.accept('./rootReducers', () => {
    const nextReducer = require('./rootReducers').default;
    store.replaceReducer(nextReducer);
  });
}

export type RootState = ReturnType<typeof store.getState>;

if (process.env.NODE_ENV !== 'test') {
  sagaMiddleware.run(rootSagas);
}
