import React, { Fragment } from 'react';
import { compose } from 'recompose';

interface OwnProps {
  breadCrumbs?: JSX.Element;
  children: any;
  extraClasses?: string;
}

type Props = OwnProps;
class Container extends React.Component<Props, {}> {
  public constructor(props: Props) {
    super(props);
  }

  public render() {
    const { children, breadCrumbs, extraClasses } = this.props;

    return (
      <div id="container" className={'flex flex-col ' + extraClasses}>
        {breadCrumbs && (
          <div className="pt-6 px-6 top-0 grid sm:grid-cols-1 md:grid-cols-2 2xl:metco-container-width m-auto">
            <div>{breadCrumbs}</div>
          </div>
        )}
        <div id="container-body" className="flex-grow mx-auto 2xl:metco-container-width max-metco-container">
          {children}
        </div>
      </div>
    );
  }
}

const container = compose<Props, OwnProps>()(Container);

export default container;
