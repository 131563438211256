export const AUTHORIZE = 'core/AUTHORIZE';
export const AUTHORIZE_SUCCESS = 'core/AUTHORIZE_SUCCESS';
export const AUTHORIZE_FAIL = 'core/AUTHORIZE_FAIL';

export const NAVIGATE_TO = 'core/NAVIGATE_TO';

export const TOOL_CHECK = 'core/TOOL_CHECK';
export const TOOL_CHECK_SUCCESS = 'core/TOOL_CHECK_SUCCESS';
export const TOOL_CHECK_FAIL = 'core/TOOL_CHECK_FAIL';

export const TOOL_CONNECTION_SUCCESS = 'core/TOOL_CONNECTION_SUCCESS';
export const TOOL_CONNECTION_FAIL = 'core/TOOL_CONNECTION_FAIL';

export const DISCONNECT_TOOL = 'core/DISCONNECT_TOOL';
export const DISCONNECT_TOOL_SUCCESS = 'core/DISCONNECT_TOOL_SUCCESS';
export const DISCONNECT_TOOL_FAIL = 'core/DISCONNECT_TOOL_FAIL';
export const CLEAR_TOOL_CONNECTION_STATE = 'core/CLEAR_TOOL_CONNECTION_STATE';

export const CONNECTION_SERIAL_PORT_OPEN = 'core/CONNECTION_SERIAL_PORT_OPEN';
export const CONNECTION_SERIAL_PORT_OPEN_SUCCESS = 'core/CONNECTION_SERIAL_PORT_OPEN_SUCCESS';
export const CONNECTION_SERIAL_PORT_CLOSE = 'core/CONNECTION_SERIAL_PORT_CLOSE';
export const CONNECTION_SERIAL_PORT_CLOSE_SUCCESS = 'core/CONNECTION_SERIAL_PORT_CLOSE_SUCCESS';
export const CONNECTION_SERIAL_PORT_ERROR = 'core/CONNECTION_SERIAL_PORT_ERROR';

export const SHOW_TOAST = 'core/SHOW_TOAST';
export const REMOVE_TOAST = 'core/REMOVE_TOAST';

export const RECEIVED_NEW_REMOTE_CONFIG_VALUES = 'core/RECEIVED_NEW_REMOTE_CONFIG_VALUES';

export const SET_PORT_BUSY_STATUS = 'core/SET_PORT_BUSY_STATUS';

export const REQUEST_ITEM_INSTANCE_SUCCESS = 'core/REQUEST_ITEM_INSTANCE_SUCCESS';
export const REQUEST_ITEM_INSTANCE_FAIL = 'core/REQUEST_ITEM_INSTANCE_FAIL';

export const SET_OPENLINK_SERVICE_TYPE = 'core/SET_OPENLINK_SERVICE_TYPE';

export const REQUEST_NEARBY_ITEM_INSTANCE_BATCH_SUCCESS = 'common/REQUEST_NEARBY_ITEM_INSTANCE_BATCH_SUCCESS';

export const INITIALIZE_BLED112_DONGLE = 'core/INITIALIZE_BLED112_DONGLE';
export const BLE_START_SCANNING = 'core/BLE_START_SCANNING';
export const BLE_START_SCANNING_SUCCESS = 'core/BLE_START_SCANNING_SUCCESS';
export const BLE_START_SCANNING_FAIL = 'core/BLE_START_SCANNING_FAIL';

export const BLE_STOP_SCANNING = 'core/BLE_STOP_SCANNING';
export const BLE_STOP_SCANNING_SUCCESS = 'core/BLE_STOP_SCANNING_SUCCESS';
export const BLE_STOP_SCANNING_FAIL = 'core/BLE_STOP_SCANNING_FAIL';

export const BLE_EVENT_ONEKEY_ADVERT_RECEIVED = 'core/BLE_EVENT_ONEKEY_ADVERT_RECEIVED';
export const BLE_EVENT_TOOL_DISCONNECTED = 'core/BLE_EVENT_TOOL_DISCONNECTED';

export const BLE_CLEAR_NEARBY_DEVICES = 'core/BLE_CLEAR_NEARBY_DEVICES';

export const CONNECT_VIA_BLE = 'core/CONNECT_VIA_BLE';
export const CONNECT_VIA_BLE_SUCCESS = 'core/CONNECT_VIA_BLE_SUCCESS';
export const CONNECT_VIA_BLE_FAIL = 'core/CONNECT_VIA_BLE_FAIL';

export const TARGET_IN_BOOTLOADER_MODE = 'core/TARGET_IN_BOOTLOADER_MODE';
export const TARGET_IN_BOOTLOADER_MODE_FAIL = 'core/TARGET_IN_BOOTLOADER_MODE_FAIL';

export const SET_CONNECTION_ATTEMPT_STATUS_MESSAGE = 'core/SET_CONNECTION_ATTEMPT_STATUS_MESSAGE';
export const SET_PREVIOUS_CONNECTION_ATTEMPT_RESULT = 'core/SET_PREVIOUS_CONNECTION_ATTEMPT_RESULT';

export const SET_COMMUNICATION_ADAPTER_MODEL = 'core/SET_COMMUNICATION_ADAPTER_MODEL';
