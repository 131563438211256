import { PayloadAction } from '@reduxjs/toolkit';
import { ResultAction } from 'app/applicationTypes';

import { Toast } from './models/Toast';

import * as actionTypes from 'domains/core/actionTypes';

export const doShowToast = (toast: Toast): ResultAction => {
  return {
    type: actionTypes.SHOW_TOAST,
    result: toast
  };
};

export const doRemoveToast = (toastId: number): ResultAction => {
  return {
    type: actionTypes.REMOVE_TOAST,
    result: toastId
  };
};

export const doNavigateTo = (view: string): PayloadAction<string> => {
  return {
    type: actionTypes.NAVIGATE_TO,
    payload: view
  };
};

export const doAuthorize = (payload: string): PayloadAction<string> => {
  return {
    type: actionTypes.AUTHORIZE,
    payload
  };
};
