import { store } from 'app/store';
import { SHOW_TOAST } from '../actionTypes';

export class Toast {
  public id: number;
  public title: string;
  public subTitle?: string;
  public canManuallyDismiss: boolean;
  public autoDismissAfterSeconds?: number;
  public type: ToastType;

  public constructor(id: number, type: ToastType, title: string, subTitle?: string, canManuallyDismiss?: boolean, autoDismissAfterSeconds?: number) {
    this.id = id;
    this.title = title;
    this.subTitle = subTitle;
    this.canManuallyDismiss = canManuallyDismiss ?? false;
    this.autoDismissAfterSeconds = autoDismissAfterSeconds;
    this.type = type;
  }
}

let lastToastId = 0;

export default function createToast(type: ToastType = ToastType.info, title: string, subTitle?: string, canManuallyDismiss: boolean = true, autoDismissAfterSeconds?: number) {
  return new Toast(lastToastId++, type, title, subTitle, canManuallyDismiss, autoDismissAfterSeconds);
}

export function toastFailure(title: string, subtitle: string) {
  store.dispatch({
    type: SHOW_TOAST,
    result: createToast(ToastType.error, title, subtitle, false, 2.5)
  });
}

export function toastSuccess(title: string, subtitle: string) {
  store.dispatch({
    type: SHOW_TOAST,
    result: createToast(ToastType.success, title, subtitle, false, 2.5)
  });
}

export function toastInfo(title: string, subtitle: string, time: number = 2.5) {
  store.dispatch({
    type: SHOW_TOAST,
    result: createToast(ToastType.info, title, subtitle, false, time)
  });
}

export function toastWarn(title: string, subtitle: string) {
  store.dispatch({
    type: SHOW_TOAST,
    result: createToast(ToastType.warning, title, subtitle, false, 2.5)
  });
}

export enum ToastType {
  info,
  warning,
  error,
  success
}
