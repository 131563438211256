import { ApplicationState } from 'app/applicationTypes';
import { store } from 'app/store';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Toast, ToastType } from '../models/Toast';
import { doRemoveToast } from '../actions';

interface StateProps {
  toasts: Toast[];
}

export const Toasts = ({ toasts }: StateProps) => {
  return (
    <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start mt-12">
      <ul className="w-full">
        {toasts.flatMap((t) => {
          return <SingleToast key={t.id} toast={t} />;
        })}
      </ul>
    </div>
  );
};
const container = connect<StateProps, {}, {}, ApplicationState>((state: ApplicationState) => ({
  toasts: state.domains.core.toasts
}))(Toasts);

export default container;

interface SingleToastProps {
  toast: Toast;
}

const SingleToast = ({ toast }: SingleToastProps) => {
  useEffect(() => {
    if (toast.autoDismissAfterSeconds != undefined && toast.autoDismissAfterSeconds > 0) {
      setTimeout(() => {
        store.dispatch(doRemoveToast(toast.id));
      }, toast.autoDismissAfterSeconds * 1000);
    }
  }, [toast]);

  return (
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end mb-2">
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <ToastIcon type={toast.type} />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p data-testid="toast-title" className="text-sm font-medium text-gray-900">
                {toast.title}
              </p>
              <p data-testid="toast-sub-title" className="mt-1 text-sm text-gray-500">
                {toast.subTitle}
              </p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              {toast.canManuallyDismiss && (
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={() => {
                    store.dispatch(doRemoveToast(toast.id));
                  }}
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ToastIconProps {
  type: ToastType;
}

const ToastIcon = ({ type }: ToastIconProps) => {
  const iconClasses = (type: ToastType) => {
    switch (type) {
      case ToastType.info:
        return 'fas fa-1x fa-info-circle text-gray-500';
      case ToastType.success:
        return 'fas fa-1x fa-check-circle text-green-500';
      case ToastType.warning:
        return 'fas fa-1x fa-exclamation-triangle text-yellow-500';
      case ToastType.error:
        return 'fas fa-1x fa-exclamation-circle text-red-500';
      default:
        break;
    }
  };

  return <i data-testid="toast-icon" className={iconClasses(type)} />;
};
