import { all, call, fork, put, take } from 'redux-saga/effects';

import history from 'app/history';
import { setJwt } from 'domains/utils/apiHelpers';

import { ResultAction } from 'app/applicationTypes';
import _ from 'lodash';

import * as actionTypes from './actionTypes';

export function* watchForAuthorize() {
  while (true) {
    const { payload } = yield take(actionTypes.AUTHORIZE);

    try {
      yield call(setJwt, payload);

      yield put({ type: actionTypes.AUTHORIZE_SUCCESS });
    } catch (error) {
      yield put({ type: actionTypes.AUTHORIZE_FAIL, error: error });
      history.push('/');
    }
  }
}

function* logAllFailActions() {
  while (true) {
    const action: ResultAction = yield take('*');
    if (/(FAILURE|FAIL|ERROR|ERRORS)$/.test(action.type)) {
      console.error(action.error!.message, action.error?.stack);
    }
  }
}

export default function* watchForCoreSagas(): any {
  yield all([fork(watchForAuthorize), fork(logAllFailActions)]);
}
