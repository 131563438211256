import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Auth0Login, LandingPage } from 'domains/core/containers';
import { useAuth0 } from '@auth0/auth0-react';
import { doAuthorize } from 'domains/core/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'app/applicationTypes';
import { setUserLocality } from 'domains/common/actions';
import 'App.css';

interface StateProps {
  isAuthorized: boolean;
  remoteConfigs: any | undefined;
}

interface DispatchProps {
  doAuthorize: typeof doAuthorize;
  setUserLocality: typeof setUserLocality;
}

type Props = DispatchProps & StateProps;
function App(props: Props) {
  const { doAuthorize, isAuthorized, setUserLocality } = props;
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !hasToken && !isAuthorized) {
      getAccessTokenSilently().then((data) => {
        setHasToken(true);
        doAuthorize(data);
      });
    }
  });

  return (
    <Switch>
      <Route path="/" component={LandingPage} />
      <Route path="/login" component={Auth0Login} />
    </Switch>
  );
}

const container = connect<StateProps, DispatchProps, {}, ApplicationState>(
  (state: ApplicationState) => ({
    isAuthorized: state.domains.core.isAuthorized,
    remoteConfigs: state.domains.core.remoteConfigs
  }),
  {
    doAuthorize,
    setUserLocality
  }
)(App);

export default container;
