import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import { Route, Router } from 'react-router-dom';
import history from 'app/history';
import Auth0 from 'app/Auth0';
import './App.css';
import * as serviceWorker from './serviceWorkerRegistration';
import { configureAnalytics } from './domains/utils/analytics';
import { configureFeatureToggling } from 'domains/utils/featureToggles';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={App} />
        <Auth0>
          <Route path="/" component={App} />
        </Auth0>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// configureAnalytics();
// configureFeatureToggling();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
