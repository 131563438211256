import _ from 'lodash';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

interface UserActionDropdownOption {
  label: string;
  onClick(): void;
}

interface OwnProps {
  label: string;
  options: UserActionDropdownOption[];
  disabled?: boolean;
}

type Props = OwnProps;
const UserActionDropdown = (props: Props) => {
  const { label, options, disabled } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-xl primary-button text-sm py-2 px-6 disabled:opacity-50" disabled={disabled}>
          {label}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {_.map(options, (x, i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <div className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer')} onClick={x.onClick}>
                    {x.label}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserActionDropdown;
