import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { Button, FullPageOverlay } from 'domains/core/components';
import { ApplicationState } from 'app/applicationTypes';
import { ButtonSize, ButtonStyle } from '../../common/models/Enums';
// import logo from 'assets/logo.png';

import 'App.css';

interface StateProps {
  authorizationError: string | undefined;
}

type Props = StateProps;
const Auth0Login = (props: Props) => {
  const { authorizationError } = props;
  const { error, isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div>
      <div className="modal fixed w-full h-full top-0 left-0 flex flex-col gap-6 items-center justify-center">
        {isLoading && <FullPageOverlay />}
        <div className="bg-white w-11/12 md:max-w-md mx-auto rounded-xl shadow-lg overflow-y-auto">
          <div className="text-left p-6">
            <div className="flex justify-center items-center">{/* <img alt="mke-logo" src={logo} className="w-48" /> */}</div>
            {(error || authorizationError) && <div className="flex justify-center items-center pt-3 text-xs">{error?.message || authorizationError}</div>}
          </div>
          <Button
            dataTestId="button-log-in"
            buttonSize={ButtonSize.dialog}
            buttonStyle={ButtonStyle.destructive}
            label={isAuthenticated ? 'Log Out' : 'Log In'}
            type="button"
            fullWidth
            onClick={isAuthenticated ? logout : loginWithRedirect}
          />
        </div>
      </div>
    </div>
  );
};

const container = connect<StateProps, {}, {}, ApplicationState>((state: ApplicationState) => ({
  authorizationError: state.domains.core.authorizationError
}))(Auth0Login);

export default container;
