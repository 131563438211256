import { ResultAction } from 'app/applicationTypes';
import { RemoteConfigValues } from 'domains/utils/featureToggles';

import * as actionTypes from './actionTypes';
import { Toast } from './models/Toast';

export interface State {
  isDev: boolean;
  isAuthorized: boolean;
  authorizationError: string | undefined;
  remoteConfigs: RemoteConfigValues;
  toasts: Toast[];
}

export const initialState: State = {
  isDev: process.env.NODE_ENV === 'development',
  isAuthorized: false,
  authorizationError: undefined,
  remoteConfigs: {},
  toasts: []
};

export function coreReducer(state = initialState, action: ResultAction): State {
  switch (action.type) {
    case actionTypes.AUTHORIZE:
      return {
        ...state,
        isAuthorized: false
      };

    case actionTypes.AUTHORIZE_SUCCESS:
      return {
        ...state,
        isAuthorized: true
      };

    case actionTypes.AUTHORIZE_FAIL:
      return {
        ...state,
        isAuthorized: false,
        authorizationError: action.error?.message ?? 'Unknown Error'
      };

    case actionTypes.SHOW_TOAST:
      return {
        ...state,
        toasts: [action.result, ...state.toasts]
      };

    case actionTypes.REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.result)
      };

    case actionTypes.RECEIVED_NEW_REMOTE_CONFIG_VALUES:
      return {
        ...state,
        remoteConfigs: action.result
      };
    default:
      return state;
  }
}
