import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { useAuth0 } from '@auth0/auth0-react';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from 'app/applicationTypes';

interface DispatchProps {}

interface StateProps {}

export type Props = StateProps & DispatchProps & RouteComponentProps;
export const UserDropdown = (props: Props) => {
  const { logout, user } = useAuth0();

  const logoutHandler = () => {
    logout();
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div data-testid="user-dropdown-menu">
            <Menu.Button data-testid="user-menu-button" className="p-3 text-left inline-flex justify-center w-full rounded-xl hover:bg-gray-800 focus:outline-none">
              <div>
                <div className="label-text">{user?.name ?? ''}</div>
                <div className="value-text">{user?.email ?? ''}</div>
              </div>
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-50" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items static className="origin-top-right absolute right-0 mt-3 w-80 rounded-xl shadow-lg bg-gray-800 focus:outline-none z-20">
              <div>
                <Menu.Item>
                  <button className="text-left block px-6 py-3 label-text hover:bg-gray-900 w-full rounded-b-xl" onClick={logoutHandler}>
                    Sign Out
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const container = compose<Props, {}>(
  withRouter,
  connect<StateProps, {}, {}, ApplicationState>((state: ApplicationState) => ({}), {})
)(UserDropdown);

export default container;
