import _ from 'lodash';
const accessTokenKey = 'accessTokenKey';

export const getServiceAppJwt = (): string | null | undefined => {
  return sessionStorage.getItem(accessTokenKey) || '';
};

export const setJwt = (token: string) => {
  sessionStorage.setItem(accessTokenKey, token);
};

export const getAuth0Response = (): string | undefined => {
  const auth0key = _(localStorage)
    .keys()
    .find((x) => x.indexOf(process.env.REACT_APP_AUTH0_CLIENT_ID!) > -1);
  const response = localStorage.getItem(auth0key!);
  return response ? JSON.parse(response).body : undefined;
};
