export enum ButtonType {
  pill,
  text
}

export enum ButtonStyle {
  primary,
  destructive,
  warning,
  cancel
}

export enum ButtonSize {
  extraSmall,
  small,
  medium,
  large,
  dialog,
  extraLarge
}

export enum HeaderStyle {
  primary,
  destructive
}

export enum BaseUrlType {
  TODO = 'TODO'
}
