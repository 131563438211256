import _ from 'lodash';
import { ResultAction } from 'app/applicationTypes';
import * as actionTypes from 'domains/common/actionTypes';

export interface State {
  someState: string | undefined;
}

export const initialState: State = {
  someState: undefined
};

export function commonReducer(state = initialState, action: ResultAction): State {
  switch (action.type) {
    default:
      return state;
  }
}
