import { compose } from 'recompose';

interface OwnProps {
  title: string;
  description?: any;
  primaryLabel?: string;
  primaryOnClick(): void;
  secondaryLabel?: string;
  secondaryOnClick?(): void;
  children?: any;
  primaryOptionDataTestId?: string;
}

type Props = OwnProps;
const Modal = (props: Props) => {
  const { title, description, primaryLabel, secondaryLabel, primaryOnClick, secondaryOnClick, children, primaryOptionDataTestId } = props;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left overflow-x-auto">
                <h3 data-testid="modal-title" className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  {title}
                </h3>
                {description && (
                  <div className="mt-2">
                    <p data-testid="modal-description" className="text-sm text-gray-500">
                      {description}
                    </p>
                  </div>
                )}
                {children && <div className="mt-2 max-h-56 overflow-y-auto">{children}</div>}
              </div>
            </div>
          </div>
          <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              data-testid={primaryOptionDataTestId}
              type="button"
              onClick={primaryOnClick}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-gray-50 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {primaryLabel || 'OK'}
            </button>
            {secondaryLabel && secondaryOnClick && (
              <button
                type="button"
                onClick={secondaryOnClick}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {secondaryLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const container = compose<Props, OwnProps>()(Modal);

export default container;
